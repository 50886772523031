var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { textDecoration } from '../../theme/system-utilities';
import { Text } from '../Text';
var SMALL_COUNTER_STYLES = {
    fontSize: ['fs_12', null, 'fs_14', 'fs_16'],
    lineHeight: ['lh_16', null, null, 'lh_20'],
    ml: 'sp_0',
};
var Container = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
Container.defaultProps = {
    fontSize: ['fs_14', null, null, 'fs_16'],
    lineHeight: ['lh_18', null, null, 'lh_24'],
    color: 'sys.neutral.text.default',
};
var Count = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  text-decoration-thickness: 0.5px;\n  text-underline-offset: ", "px;\n"], ["\n  ", ";\n  text-decoration-thickness: 0.5px;\n  text-underline-offset: ", "px;\n"])), textDecoration, themeGet('space.sp_2'));
var Counter = function (_a) {
    var count = _a.count, isUnderlined = _a.isUnderlined, isSmall = _a.isSmall;
    if (!count) {
        return null;
    }
    return (React.createElement(Container, __assign({ "data-testid": "rating-counter" }, (isSmall && SMALL_COUNTER_STYLES)),
        "(",
        React.createElement(Count, { textDecoration: isUnderlined ? 'underline' : 'none' }, count),
        ")"));
};
export default Counter;
var templateObject_1, templateObject_2;
