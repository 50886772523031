var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { display, size as systemSize, space } from 'styled-system';
import starEmpty from '../../assets/icons/star-empty.svg?sprite';
import star from '../../assets/icons/star.svg?sprite';
import { fill } from '../../theme/system-utilities';
import Icon from '../Icon';
var StyledIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, systemSize, space, fill);
StyledIcon.defaultProps = {
    display: 'inline-block',
    mr: ['sp_4'],
    fill: 'sys.neutral.icon.default',
};
var Star = function (_a) {
    var size = _a.size, isFull = _a.isFull;
    return (React.createElement(StyledIcon, { size: size, icon: isFull ? star : starEmpty }));
};
export default Star;
var templateObject_1;
