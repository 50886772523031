var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactRating from 'react-rating';
import styled from '@emotion/styled';
import { alignItems, display, flexDirection, height, space, themeGet, width } from 'styled-system';
import { useKeyboardSelect } from '../../../common/hooks/keyboard';
import { RatingSize } from '../../enums/rating';
import { cursor, flexShrink, themeHeight, whiteSpace } from '../../theme/system-utilities';
import Counter from './Counter';
import { useReactRatingProps } from './hooks';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus-visible {\n    color: ", ";\n    outline-offset: ", "px;\n    outline-color: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus-visible {\n    color: ", ";\n    outline-offset: ", "px;\n    outline-color: ", ";\n  }\n"])), display, space, width, themeHeight, flexDirection, alignItems, whiteSpace, flexShrink, cursor, themeGet('colors.sys.neutral.text.active'), themeGet('space.sp_2'), themeGet('colors.sys.secondary.border.default'));
var containerStyles = {
    display: 'inline-flex',
    width: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flexShrink: 1,
};
var StyledReactRating = styled(ReactRating)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  overflow: hidden;\n"], ["\n  ", ";\n  overflow: hidden;\n"])), height);
var Rating = function (_a) {
    var _b = _a.size, size = _b === void 0 ? RatingSize.Medium : _b, _c = _a.readonly, readonly = _c === void 0 ? true : _c, rate = _a.rate, count = _a.count, onClick = _a.onClick, className = _a.className, onChange = _a.onChange, _d = _a.withBackground, withBackground = _d === void 0 ? false : _d;
    var reactRatingProps = useReactRatingProps({
        rate: rate,
        size: size,
        readonly: readonly,
        onChange: onChange,
    });
    var handleKeyDown = useKeyboardSelect(onClick);
    return (React.createElement(Container, __assign({ className: className, "data-testid": "rating", role: "button", onClick: onClick, onKeyDown: handleKeyDown }, (!!onClick && { cursor: 'pointer', tabIndex: 0 }), (withBackground && {
        themeHeight: ['sp_20', null, 'sp_24', 'sp_32'],
        mt: ['sp_16', null, null, 'sp_24'],
    }), containerStyles),
        React.createElement(StyledReactRating, __assign({}, reactRatingProps)),
        React.createElement(Counter, { count: count, isSmall: size === RatingSize.Small, isUnderlined: !!onClick })));
};
export default Rating;
var templateObject_1, templateObject_2;
