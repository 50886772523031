import React, { useMemo } from 'react';
import { RatingSize } from '../../enums/rating';
import Star from './Star';
var getRatingSize = function (ratingSize) {
    switch (ratingSize) {
        case RatingSize.Small:
            return [12, 14, null, 18];
        case RatingSize.Large:
            return [16, null, null, 18];
        case RatingSize.ExtraLarge:
            return 36;
        default:
            return [14, null, null, 18];
    }
};
/**
 * We need to slightly modify rate in order to have it displayed properly.
 * That's because right-margin has an impact on the mask generated by react-rating library,
 * resulting in inaccurate mask width (e.g. "3.7" is visible as full star).
 *
 */
export var getInitialRating = function (rate) {
    var rateFraction = rate - Math.trunc(rate);
    if (rateFraction <= 0.3)
        return rate + 0.05;
    if (rateFraction <= 0.6)
        return rate - 0.05;
    if (rateFraction <= 0.7)
        return rate - 0.1;
    return rate - 0.2;
};
export var useReactRatingProps = function (_a) {
    var rate = _a.rate, size = _a.size, readonly = _a.readonly, onChange = _a.onChange;
    return useMemo(function () {
        var starSize = getRatingSize(size);
        return {
            readonly: readonly,
            onChange: onChange,
            initialRating: getInitialRating(rate),
            emptySymbol: React.createElement(Star, { size: starSize }),
            fullSymbol: React.createElement(Star, { size: starSize, isFull: true }),
            height: starSize,
        };
    }, [readonly, onChange, rate, size]);
};
